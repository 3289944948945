.galleryList{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    .galleryItem{
        cursor: pointer;
        display: block;
        flex: 25%;
        overflow: hidden;
        position: relative;

        .galleryItemHover{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.7);
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            opacity: 0;
            transition: opacity .5s ease;
            z-index: 2;
        }
        &:hover .galleryItemHover{
            opacity: 1;
        }
        img{
            max-width: 100%;
            height: auto;
            transition: all .5s ease;
        }
        &:hover img{
            transform: scale(1.3);
        }

    }
    @media(max-width: 900px){
        .galleryItem{
            flex: 50%;
        }
    }
}