.slick-dots{
    position: static;
}
.slick-dots li button:before{
    color:green;
    font-size: 15px;
}
.slick-dots li.slick-active button:before{
    color:green;
}
.slick-list {
    margin: 0 -10px;
}
.slick-slide>div {
    padding: 0 10px;
}

.slick-slide h3 {
    margin-top: 24px;
}