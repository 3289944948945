.container{
    margin: 0 40px 72px 40px;
    .mainInfo{
        display: flex;
        justify-content: space-around;
        
        margin-bottom: 72px;
        .information{
            display: flex;
            flex-direction: column;
            .price{
                color: green;
                font-weight: bold;
                font-size: larger;
            }
        }
    }
    .details{
        display: flex;
        flex-direction: column;
    }
}
    
@media(max-width: 900px){
    .container{
        .mainInfo{
            flex-wrap: wrap;
            .information{
                text-align: center;
            }
        }
    }
}