.main{
    position: relative;
    margin-left: 30%;

    .mainImage{
        margin-bottom: 96px;
        >img{
            width: 100%;
            height: auto;
        }
    }

    .contentSection{
        position: relative;
        margin: 0 40px 72px;
        width: auto;

        .headerSection{
            margin-bottom: 36px;
            
            .sectionDescription{
                margin: -24px 0 0 0;
                font-style: italic;
            }
            .sectionButton{
                position: absolute;
                top: 15px;
                right: 0px;
                margin: 0;
                >a{
                    border: 1px solid green;
                    border-radius: 25px;
                    padding: 15px;
                    text-decoration: none;
                    color: green;
                    font-weight: bold;
                    transition-duration: 0.5s;
                    &:hover{
                        border: 1px solid rgba(0, 128, 0, 0.385);
                    }
                }
            }
        }

        .servicesWrapper{
            display: block;
            text-align: center;

            .serviceImage{
                a{
                    display: flex;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.5);
                    background-image: linear-gradient(to bottom, rgba(5, 141, 0, 0.6),transparent);

                    img{
                        border-radius: 50%;
                        width: 100%;
                        transition-duration: 0.75s;
                        &:hover{
                            opacity: 50%;
                        }
                    }
                }
            }
        }
        
        .listOfNews{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            .article{
                display: flex;
                flex-direction: column;
                width: 23%;

                .articleImage{
                    a>img{
                        width: 100%;
                        height: auto;
                        margin-bottom: 15px;
                    }
                }
                .articleTitle{
                    color: green;
                }
            }
        }

        .listPartners{
            display: block;
            text-align: center;

            .partnerImage{
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
@media(max-width: 1200px){
    .main{
        margin-left: 0%;
    }
}

@media(max-width: 900px){
    .main{
        .contentSection{
            .headerSection{
                .sectionButton{
                    position: relative;
                    margin: 25px 0;
                    padding-bottom: 25px;
                }
            }
            .listOfNews{
                flex-direction: column;
                align-items: center;
                .article{
                    width: 300px;
                    h3{
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
}