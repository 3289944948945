.bottomPanel{
    background-image: url(../../assets/img/mainImage.png);
    background-position: center center;
    position: relative;
    margin-left: 30%;
    width: auto;
    margin-top: 48px;

    .bottomPanelWrap{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 72px 40px;
        color: #fff;
        background-color: rgba(0,0,0,0.85);

        h3{
            color: rgb(1, 181, 1);
            text-transform: uppercase;
        }

        td>strong{
            color: rgb(1, 181, 1);
            margin-right: 20px;
        }

        .panelColumn{
            width: 50%;
        }
    }
}

@media(max-width: 1200px){
    .bottomPanel{
        margin-left: 0;
        .bottomPanelWrap{
            .panelColumn{
                width: 100%;
            }
        }
    }
}