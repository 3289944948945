.variousContent{
    margin: 0 40px 72px;

    .content{
        display: flex;
        margin-bottom: 72px;
        .contentImg{
            border-radius: 50%;
            width: 16%;
            height: 16%;
            margin-right: 30px;
        }
        .contentDetails{
            width:100%;
            details{
                summary{
                    margin-bottom: 1em;
                    cursor: pointer;
                    border-radius: .3em;
                    font-weight: bold;
                    padding: 20px;
                    border: 1px solid #caceca;
                    text-transform: uppercase;
                }
                .detailsItems{
                    border: 1px solid #caceca;
                    border-top: none;
                    padding: 20px 20px 0;
                    margin-top: -20px;
                    border-radius: 0 0 .3em .3em;
                    margin-bottom: 20px;
                    .itemPhoto{
                        width: 100%;
                    }
                }
            }
        }
    }
}

details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
}

@keyframes sweep{
    from{
        opacity: 0; margin-top: -30px;
    }
    to{
        opacity: 1; margin-top: -20px;
    }
}

@media(max-width: 900px){
    .variousContent{
        .content{
            flex-direction: column;
            .contentImg{
                width: 200px;
                margin-bottom: 20px;
            }
        }
    }
}