.container{
    margin: 0 40px 72px 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-gap: 1rem;
    justify-content: space-around;

    .item{
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid rgb(202, 206, 202);
        border-radius: 5px;
        margin-bottom: 40px;
        padding: 20px;
        cursor: pointer;
        height: auto;
        
        .itemName{
            color: green;
            text-align: center;
        }
        .itemPrice{
            font-weight: bold;
        }
    }
}