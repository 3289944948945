.container{
    margin: 0 40px 72px;

    .sectionRow{
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .iframeWrap{
            height: 500px;
            width: 500px;
            margin-right: 15px;
        }

        .contactsInfo{
            display: flex;
            flex: 30%;
            flex-direction: column;

            .contactItem{
                font-size: 15px;
                display: flex;
                align-items: center;
                svg{
                    margin-right: 10px;
                }
                a{
                    color: white;
                }
            }
        }
    }

}

@media(max-width: 600px){
    .container{
        .sectionRow{
            .iframeWrap{
                height: 350px;
                width: 100%;
                margin-right: 0;
            }
        }
    }
}