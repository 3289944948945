.header{
    position: fixed;
    height: 100%;
    width: 30%;
    background: linear-gradient(#23910d, #e4e008);

    .headerInner{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 0 60px 0 60px;
        height: 70%;

        .logo{
            >img{
                width: 100%;
                height: auto;
            }
        }
        .menuWrapper{
            width: 100%;

            .toggleButton{
                display: none;
                align-items: center;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 0.3);
                color: white;
                background: none;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 18px;
                cursor: pointer;
                transition: all 300ms ease-in-out;
                &:hover{
                    border: 1px solid white;
                }
                span{
                    margin-left: 5px;
                }
            }
            .menu{
                width: 100%;
                list-style-type: none;
                padding-inline-start: 0;
                >li{
                    position: relative;
                    padding: 4px 0px;
                    span{
                        display: block;
                        text-align: right;
                        font-size: 1.125em;
                        text-transform: uppercase;
                        overflow: hidden;
                        >a{
                            position: relative;
                            right: 0;
                            border: 0;
                            color: white;
                            text-decoration: none;
                            transition: padding-right 300ms ease-in-out;
                            &:before{
                                content: '';
                                position: absolute;
                                display: block;
                                top: 50%;
                                right: 100%;
                                margin: 0 10px 0 0;
                                width: 300px;
                                height: 1px;
                                background-color: rgba(255, 255, 255, 0.3);
                            }
                            &:hover{
                                padding-right: 10px;
                            }
                        }
                    }
                }  
            }
        }
    }

    .underMenuWrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
        
        background-color: rgba(74,147,59,0.50);
        .toggleButtonBottom{
            display: none;
            background: none;
            color:white;
            border: 0;
            background-color: rgb(0, 147, 0);
            border-radius: 100%;
            padding: 20px;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            height: 50px;
            width: 50px;
            cursor: pointer;
            position: absolute;
            bottom: -25px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 3;
            transition: all 300ms ease-in-out;
            &:hover{
                background-color: rgb(25, 119, 25);
            }
        }
        .headerContacts{
            color: white;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 5px;
            grid-auto-rows: 80px;
            padding: 10px;
            
            .contactItem{
                font-size: 15px;
                display: flex;
                align-items: center;
                svg{
                    margin-right: 10px;
                }
                a{
                    color: white;
                    text-decoration: none;
                }
            }
        }

        .headerSocial{
            display: flex;
            justify-content: center;
            .sotialIcon{
                border: 1px;
                color: white;
                font-size: 2em;
                margin: 0 5px;
            }
        }
    }
}

@media(max-width: 1200px){
    .header{
        position: relative;
        height: auto;
        width: 100%;
        
        .headerInner{
            padding: 40px 40px 0px;

            .logo{
                >img{
                    width: 300px;
                }
            }

            .menuWrapper{
                &:before{
                    content: '';
                    display: block;
                    top: 50%;
                    right: 100%;
                    margin-top: 20px;
                    width: 100%;
                    height: 1px;
                    background-color: rgba(255, 255, 255, 0.3);
                }
                .toggleButton{
                    display: flex;
                    margin: 20px 0;
                }
                .menu{
                    display: flex;
                    justify-content: space-between;
                    li{
                        span{
                            a{
                                &::before{
                                    height: 0;
                                    width: 0;
                                }
                                &:hover{
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .underMenuWrapper{
            padding: 0 40px 40px;

            .toggleButtonBottom{
                display: flex;
            }

            .headerContacts{
                display: flex;
                justify-content: space-between;
                padding: 0;
            }
        }
    }
}

@media(max-width: 900px){
    .header{
        .headerInner{
            .menuWrapper{
                .menu{
                    display: block;
                    li{
                        margin: 5px auto 0 auto;
                        max-width: 320px;
                        span{
                            text-align: left;
                            >a{
                                &:before{
                                    top: 50%;
                                    left: 100%;
                                    width: 300px;
                                    height: 1px;
                                    margin: 0 0 0 10px
                                }
                            }
                        }
                    }  
                }
            }
        }
        .underMenuWrapper{
            .headerContacts{
                grid-template-columns: 1fr;
            }
        }
    }
}