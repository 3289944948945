.container{
    margin: 0 40px 72px 40px;
    .nameOfPage{
        padding-bottom: 10px;
        border-bottom: 1px solid #e1e1e1;
        font-size: 3em;
    }
    .links{
        display: flex;
        align-items: center;
        margin-top: 10px;
        >a{
            text-decoration: none;
            color: green;
            position: relative;
            &:after{
                content:'';
                display: inline-block;
                width: 100%;
                height: 1px;
                background-color: green;
                position: absolute;
                bottom: 0;
                left:0;
                opacity: 0;
                transition: 0.2s ease-in-out;
            }
            &:hover:after{
                opacity: 1;
            }
        }
        .slash{
            padding: 0 5px;
            cursor: default;
        }
    }
}