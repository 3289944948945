.body{
    margin: 0 40px;
    >h2>img{
        float:right;
        max-width: 393px;
        margin: 0 0 15px 15px;
    }
    >h3{
        color: green;
    }
}

@media(max-width: 600px){
    .body{
        >h2>img{
            width: 100%;
        }
    }
}
