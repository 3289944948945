
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6{
  margin: 0;
}

p{
  margin-bottom: 24px;
  line-height: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.913);
  display: flex;
  align-items: center;
  z-index: 3;
}
.overlay img {
  cursor: pointer;
  display: block;
  max-width: 100%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay-title{
  color: #ffffff;
  position: absolute;
  display: flex;
  bottom: 12%;
  left: 20%;
  z-index: 3;
  user-select: none;
}
.overlay-counter{
  color: #ffffff;
  position: absolute;
  display: flex;
  bottom: 12%;
  right: 20%;
  z-index: 3;
  user-select: none;
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 3;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 3;
  opacity: 0.3;
  transition: 0.7;
  cursor: pointer;
}

.overlay-arrows_left:hover{
  opacity: 0.5;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 3;
  opacity: 0.3;
  transition: 0.7;
  cursor: pointer;
}

.overlay-arrows_right:hover{
  opacity: 0.5;
}