.footer{
    position: relative;
    margin-left: 30%;
    max-width: 1008px;
    padding: 48px 40px;
    width: auto;
}
@media(max-width: 1200px){
    .footer{
        margin-left: 0;
    }
}