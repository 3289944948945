.page-container{
    padding-top: 60px;
    margin-left: 30%;
    max-width: 1008px;
}

.sectionTitle{
    position: relative;
    font-size: 1.875em;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
    line-height: normal;
    margin-bottom: 24px;
}
.sectionTitle::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 9px;
    width: 5px;
    height: 21px;
    background-color: green;
}

@media(max-width: 1200px){
    .page-container{
        margin-left: 0;
        max-width: 100%;
    }
}
    